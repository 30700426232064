<template>
  <div class="login-box">
    <div class="info" v-if="loginStatus.status">
      <img :src="loginStatus.picPath || defImg" />
      <div class="color-primary mt-10">{{ loginStatus.userName }}</div>
      <p class="color-666">欢迎来到{{ loginStatus.webName }}！</p>
    </div>
    <div class="info" v-else>
      <img :src="defImg" />
      <h4 class="color-666">欢迎来到{{ loginStatus.webName }}！</h4>
    </div>
    <div class="fun flex-box-center" v-if="!loginStatus.status">
      <span class="button-primary mr-10 login c-p" @click="openLink(1)">学员登录</span>
      <span class="button-default login c-p" @click="openLink(2)">学员注册</span>
    </div>
    <div class="fun flex-box-center" v-else>
      <span class="button-gray c-p" @click="handlerExit">退出</span>
    </div>
    <ul class="other flex-box around" v-if="loginStatus.status">
      <li @click="openLink(3)">
        <div class="order">
          <img :src="'order.png' | imgFilter('all')" />
        </div>
        <p>购物车</p>
        <!-- <span v-if="orderAndCouponNum.eCartCount > 0">{{ orderAndCouponNum.eCartCount }}</span> -->
      </li>
      <li @click="openLink(4)" v-if="couponControl.UActivityFlag !== 0">
        <div class="yhq">
          <img :src="'yhq.png' | imgFilter('all')" />
        </div>
        <p>优惠券</p>
        <!-- <span v-if="orderAndCouponNum.CouponCount > 0">{{ orderAndCouponNum.CouponCount }}</span> -->
      </li>
      <li @click="openLink(6)">
        <div class="tiku">
          <img :src="'tiku.png' | imgFilter('all')" />
        </div>
        <p>题库</p>
      </li>
      <li @click="openLink(5)">
        <div class="course">
          <img :src="'course.png' | imgFilter('all')" />
        </div>
        <p>课程</p>
      </li>
    </ul>
    <div class="other flex new-user" v-else>
      <p class="f-16 fw-600 ttt">
        <img src="//img.233.com/union/union_pc/union-temphone/reg-refare.png" alt="" />
        注册用户专享权益
      </p>
      <div class="">
        <p class="new-user-info">
          <span>
            <i class="num">1</i>
            精品课程免费试听
          </span>
        </p>
        <p class="new-user-info">
          <span>
            <i class="num">2</i>
            在线题库免费做
          </span>
        </p>
        <p class="new-user-info">
          <span>
            <i class="num">3</i>
            实用备考资料
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "login-box",
  data() {
    return {
      defImg: "//img.233.com/union/union_pc/union-temphone/user-icon-default.png",
      orderAndCouponNum: {},
      couponControl: {},
    };
  },
  computed: {
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
  },
  mounted() {
    this.loginStatus.status && this.handlerGetOrderCountAndCouponCount();
  },
  methods: {
    handlerGetOrderCountAndCouponCount() {
      // this.$api.GetOrderCountAndCouponCount({}).then((res) => {
      //   this.orderAndCouponNum = res.Result;
      // });
      this.$api.GetUnionPermission({}).then((res) => {
        this.couponControl = res.Result;
      });
    },
    handlerExit() {
      localStorage.clear();
      location.reload();
    },
    openLink(type) {
      switch (type) {
        case 1:
          window.location.href = window.location.origin + "/uc/login?redirecturl=" + window.location.href;
          break;
        case 2:
          window.open(window.location.origin + "/uc/register");
          break;
        case 3:
          window.open(window.location.origin + "/order/settle");
          break;
        case 4:
          window.open(window.location.origin + "/center/order/wallet?type=coupon");
          break;
        case 5:
          window.open(window.location.origin + "/center");
          break;
        case 6:
          window.open(window.location.origin + "/center/study/tiku");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  @media screen and (min-width: 1680px) {
    right: calc(50% - 720px);
  }
  right: calc(50% - 600px);
  position: absolute;
  top: 0;
  width: 280px;
  height: 370px;
  padding-top: 21px;
  //   transform: translateY(-50%);
  box-sizing: border-box;
  background: url(//img.233.com/union/union_pc/union-temphone/login-head.png) no-repeat top center;
  background-size: contain;
  background-color: #fff;
  z-index: 10;
  .info {
    text-align: center;
    img {
      width: 66px;
      height: 66px;
      border-radius: 33px;
      vertical-align: top;
    }
    h4,
    p {
      color: #333;
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
      line-height: 17px;
    }
    h4 {
      margin-top: 21px;
    }
  }
  .fun {
    text-align: center;
    span {
      font-size: 14px;
      margin-top: 17px;
      transition: 0.2s;
      &:hover {
        transform: translateY(-3px);
      }
      &:nth-child(2) {
        box-shadow: none;
      }
      &.login {
        width: 126px;
        height: 32px;
      }
    }
  }
  .other {
    // background: #FFF;
    background: url(//img.233.com/union/union_pc/union-temphone/login-bottom-bg.png) no-repeat top center;
    background-size: cover;
    height: 158px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    &.new-user {
      padding: 20px;
      flex-direction: column;
    }
    .ttt {
      img {
        vertical-align: bottom;
      }
    }
    .new-user-info {
      margin-top: 10px;
      font-size: 14px;
      height: 22px;
      color: #ee8580;
      border-radius: 11px 0 0 11px;
      background: linear-gradient(90deg, #fff1f0, transparent);
      .num {
        width: 22px;
        height: 22px;
        text-align: center;
        display: inline-block;
        background: #ffffff;
        border: 1px solid #ffaca6;
        border-radius: 50%;
        color: #ffaca6;
      }
    }
    li {
      list-style: none;
      text-align: center;
      position: relative;
      cursor: pointer;
      .order,
      .yhq,
      .tiku,
      .course {
        height: 40px;
        width: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .course {
        img {
          margin-top: 5px;
          height: 28px;
          width: 30px;
        }
      }
      p {
        margin-top: 10px;
        color: #333;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
      }
      span {
        position: absolute;
        top: -6px;
        right: -16px;
        font-size: 12px;
        padding: 0 5px;
        height: 23px;
        background: #e13b29;
        border: 3px solid #ffffff;
        border-radius: 23px;
        color: #fff;
      }
      &:nth-child(2) i {
        right: -4px;
      }
    }
  }
}
</style>
