<template>
  <div class="home">
    <Banner></Banner>
    <LiveReport></LiveReport>
    <template v-for="(item,index) in examList.slice(0,3)">
      <CourseInfo :key="index" :classObj="item" v-if="item.childData && item.childData.length"></CourseInfo>
    </template>
    <!-- <News></News> -->
    <Teacher></Teacher>
    <Introduce></Introduce>
  </div>
</template>

<script>
import Banner from "./components/Banner.vue";
import Nav from "./components/Nav.vue";
import LiveReport from "./components/LiveReport.vue";
import News from "./components/News.vue";
import CourseInfo from "./components/CourseInfo.vue";
import Introduce from "./components/Introduce.vue";
import Teacher from "./components/Teacher.vue";
export default {
  components: {
    Nav,
    Banner,
    LiveReport,
    News,
    CourseInfo,
    Introduce,
    Teacher,
  },
  name: "home",
  data() {
    return {
    };
  },
  computed: {
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    examList() {
      return this.$store.state.common.examList;
    },
  },
  async mounted() {
    document.title = "首页" + (this.loginStatus.webName ? "-" + this.loginStatus.webName : '');
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.home {
}
</style>
