<template>
  <div class="news">
    <Title title="新闻动态"></Title>
    <div class="news-info flex">
      <div class="modules1">
        <div class="module1-content">
          <div class="modules-n-title flex-box">
            <i class="tag">最新</i>
            <div class="ellipsis title hover-primary">2020年二级建造师《公路工程》模2020年二级建造师《公路工程》模2020年二级建造师《公路工程》模</div>
          </div>
          <p class="content">初级会计职称考试《初级会计实务》考前摸底卷初级会计职称考试 初级会计职称考试《初级会计实务》考前摸底卷初级会计职称考试初级会计职称考试《初级会计实务》考前摸底卷初级会计职称考试初级会计职称考试《初级会计实务》考前摸底卷初级会计职称考试初级会计职称考试《初级考前摸底卷初级会计职称考试初级会计职称考初级会计职称考试《初级考前摸底卷初级会计职称考试初级会计职称考</p>
          <p class="from color-999 f-15 flex-box">
            2020-12-04
            <span class="line">|</span>
            来源233网校
          </p>
        </div>
      </div>
      <div class="modules1">
        <div class="module1-content">
          <div class="modules-n-title flex-box">
            <i class="tag">最新</i>
            <div class="ellipsis title">2020年二级建造师《公路工程》模2020年二级建造师《公路工程》模2020年二级建造师《公路工程》模</div>
          </div>
          <p class="content">初级会计职称考试《初级会计实务》考前摸底卷初级会计职称考试 初级会计职称考试《初级考前摸底卷初级会计职称考试初级会计职称考初级会计职称考试《初级考前摸底卷初级会计职称考试初级会计职称考</p>
          <p class="from color-999 f-15 flex-box lh-15">
            2020-12-04
            <span class="line">|</span>
            来源233网校
          </p>
        </div>
      </div>
      <div class="modules3">
        <Title :showmore="true">
          <div slot="left" class="f-22">动态</div>
        </Title>
        <div class="module3-content">
          <div class="flex-box modules-item c-p" v-for="(item, index) in modulesList.slice(0, 3)" :key="index">
            <div class="ellipsis--two-line cont">有效期内笔试未过退费，如个人原因缺考因缺考有效期内笔试未过退费，如个人原因缺考因缺考</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";

export default {
  components: {
    Title,
  },
  name: "news",
  data() {
    return {
      modulesList: [1, 2, 3, 4, 5],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.news {
  margin: 0 auto;
  margin-top: 60px;
  @include pageSize;
  .news-info {
    margin-top: 29px;
    height: 213px;
  }
  .modules1 {
    background: #fff;
    height: 100%;
    width: 524px;
    padding: 20px;
    margin-right: 22px;
    flex-shrink: 0;
    .module1-content {
      cursor: pointer;
      .modules-n-title {
        font-size: 18px;
        line-height: 22px;
        .tag {
          width: 50px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          text-align: center;
          border: 1px solid #fff;
          border-radius: 4px;
          flex-shrink: 0;
          @include font_color("color1");
          @include border_color("color1");
        }
        .title {
          font-size: 22px;
          margin-left: 10px;
        }
      }
      .content {
        margin: 15px 0;
        font-size: 15px;
        color: #555555;
        height: 104px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 26px;
      }
      .from {
        .line {
          margin: 0 20px;
        }
      }
      &:hover {
        @include font_color("color1");
      }
    }
  }

  .modules3 {
    padding: 10px 20px;
    background: #fff;
    width: 340px;
    .module3-content {
      margin-top: 8px;
      font-size: 15px;
      .modules-item {
        color: #555555;
        &:not(:last-of-type) {
          margin-bottom: 6px;
        }
        .cont {
          max-height: 44px;
          line-height: 22px;
        }
        &:hover {
          @include font_color("color1");
          span {
            @include font_color("color1");
          }
        }
      }
    }
  }
}
</style>
