<template>
  <div class="live-preport" v-if="lives.length">
    <Title title="直播预告" :showmore="true" @more="handlerGetMore"></Title>
    <div class="live-swiper-box my-swiper-prev-button">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(item, index) in lives" :key="index">
          <div class="live-item flex p-r">
            <div class="flex live-item-con">
              <div class="left">
                <div class="teacher-icon">
                  <img
                    :src="item.teacherPic || defaultTeacherImage"
                    @error="
                      (e) => {
                        e.target.src = defaultTeacherImage;
                      }
                    "
                    alt=""
                  />
                </div>
                <div class="color-999 mt-10 text-c">{{ item.teacherName }}</div>
              </div>
              <div class="right">
                <div class="title ellipsis--two-line f-16">{{ item.title }}</div>
                <div class="mt-20 flex-box between">
                  <div class="color-999">
                    <!-- <p class="lh-15">一级建造师</p> -->
                    <p class="mt-10">{{ item.liveDateStr }}</p>
                  </div>
                  <a target="_blank" href="javascript:;" @click="handler2Live(item)" :class="[![1, 2, 4].includes(item.liveState) ? 'button-default' : 'button-primary']" class="button c-p">{{ liveName(item) }}</a>
                </div>
              </div>
            </div>
            <div class="status p-a" :style="liveStyle"></div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev" v-if="lives.length > 3"></div>
        <div class="swiper-button-next" slot="button-next" v-if="lives.length > 3"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import { objToStr } from "@/utils";
export default {
  components: {
    Title,
  },
  name: "live-report",
  data() {
    return {
      liveList: [],
      swiperOptions: {
        slidesPerGroup: 3,
        slidesPerView: 3,
        spaceBetween: 24,
        paginationClickable: true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
      },
      defaultTeacherImage: "https://img2.233.com/zb/images/default_teacher_pic.png",
    };
  },
  computed: {
    liveStyle() {
      return {
        // backgroundImage: `url(${this.$options.filters.ImgFilter('zb_label.png')})`
      };
    },
    lives() {
      // const _arr = this.liveList.filter((item) => new Date(item.startTime) > new Date());
      // const _arr = this.liveList.filter((item) => [1, 2, 4].includes(item.liveState));
      // return _arr ? _arr.sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime)) : [];
      return this.liveList;
    },
  },
  mounted() {
    this.handlerGetLive();
  },
  methods: {
    liveName(item) {
      let text = "已结束";
      switch (item.liveState) {
        case 1:
          text = "已预约";
          break;
        case 2:
          text = "立即预约";
          break;
        case 4:
          text = "直播中";
          break;
        case 9:
          // text = "去回看";
          break;
        case 8:
          // text = "回看上传中";
          break;
        case 7:
          // text = "";
          break;

        default:
          break;
      }
      return text;
    },
    handlerGetMore() {
      window.open(window.location.origin + "/m/zhibo/#/");
    },
    handler2Live(item) {
      window.open(window.location.origin + `/m/zhibo/#/?${objToStr({
        domain: item.domain,
        status: 2 // 付费的
      })}`);
    },
    async handlerGetLive() {
      // 只查付费的
      const { data = [] } = await this.$api.getLiveReport({ isFree: 2 });
      this.liveList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.live-preport {
  @include pageSize;
  margin: 0 auto;
  .live-swiper-box {
    margin-top: 29px;
    .live-item {
      cursor: pointer;
      width: 384px;
      @media screen and (min-width: 1680px) {
        width: 464px;
      }
      height: 144px;
      background: #ffffff;
      box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
      .left {
        margin-right: 24px;
        .teacher-icon {
          overflow: hidden;
          width: 60px;
          height: 60px;
          background: #f2f4f3;
          border-radius: 50%;
          position: relative;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .right {
        width: 100%;
        .title {
          line-height: 24px;
          max-height: 48px;
          height: 48px;
        }
        .button {
          width: 90px;
          height: 32px;
          line-height: 32px;
        }
      }
      &:hover {
        .title {
          @include font_color("color1");
        }
      }
    }
    .status {
      top: 0;
      left: 0;
      width: 78px;
      height: 24px;
      background-size: cover;
    }
  }
  .live-item-con {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  // .swiper-slide-active {
  //   .live-item-con {
  //     padding: 0 18px 0 43px;
  //   }
  // }
  // .swiper-slide-next {
  //   .live-item-con {
  //     padding: 0 13px 0 18px;
  //   }
  // }
}
</style>
