<template>
  <div class="banner" @mouseover="enter" @mouseleave="leave" >
    <swiper :options="swiperOptions" ref="banner" class="my-swiper-prev-button h100 w100">
      <swiper-slide v-for="item in banners" :key="item.Id">
        <a target="_blank" :href="item.Href" v-if="item.Href">
          <img :src="item.ImgUrl" />
        </a>
        <a v-else>
          <img :src="item.ImgUrl" />
        </a>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination" v-show="banners.length > 1"></div>
      <div class="swiper-button-prev" slot="button-prev" v-show="banners.length > 1"></div>
      <div class="swiper-button-next" slot="button-next" v-show="banners.length > 1"></div>
    </swiper>
    <LoginBox></LoginBox>
    <Nav></Nav>
    <Descript></Descript>
  </div>
</template>

<script>
import Nav from "./Nav.vue";
import LoginBox from "./LoginBox.vue";
import Descript from "./Descript.vue";

export default {
  components: {
    LoginBox,
    Nav,
    Descript,
  },
  name: "banner",
  data() {
    return {
      swiperOptions: {
        pagination: ".swiper-pagination",
        paginationClickable: true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
      },
      banners: [],
    };
  },
  async mounted() {
    await this.handlerQueryBanner();
    if (this.banners.length === 0) {
      this.$refs.banner.swiper.stopAutoplay();
    }
  },
  methods: {
    async handlerQueryBanner() {
      const { Result } = await this.$api.GetBannerByHost({});
      this.banners = Result;
    },
    enter() {
      this.$refs.banner.swiper.stopAutoplay();
    },
    leave() {
      this.$refs.banner.swiper.startAutoplay();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 370px;
  margin-bottom: 204px;
  a {
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 370px;
    display: flex;
    justify-content: center;
    img {
      width: 1920px;
      height: 370px;
    }
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 20px;
  }
  /deep/.swiper-pagination {
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 0.25;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      width: 28px;
      border-radius: 4px;
    }
  }
  .swiper-button-prev {
    left: calc(50% - 320px);
    @media screen and (min-width: 1680px) {
       left: calc(50% - 440px);
    }
  }
  .swiper-button-next {
    right: calc(50% - 320px);
    @media screen and (min-width: 1680px) {
       right: calc(50% - 440px);
    }
  }
}
</style>
