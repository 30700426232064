import { render, staticRenderFns } from "./Descript.vue?vue&type=template&id=a7d15846&scoped=true&"
import script from "./Descript.vue?vue&type=script&lang=js&"
export * from "./Descript.vue?vue&type=script&lang=js&"
import style0 from "./Descript.vue?vue&type=style&index=0&id=a7d15846&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7d15846",
  null
  
)

export default component.exports