<template>
  <div class="Descript flex-box" :class="{ 'new-register': showNewUserTips }">
    <!-- <template v-if="permissionYhq.UActivityFlag === 1"> -->
    <div class="new-register-item c-p" v-if="showNewUserTips" @click="openLink(1)"></div>

    <!-- 有app -->
    <template v-if="appQrcodeObj.HaveApp">
      <div class="descript-item c-p flex-box" @click="handlerOpenLink(item)" v-for="(item, index) in descList.slice(0, 3)" :key="index">
        <div class="desc-image">
          <img :src="item.img()" alt="" />
        </div>
        <div>
          <div class="f-22">{{ item.title }}</div>
          <div class="f-16 color-666 mt-10">{{ item.subtitle }}</div>
        </div>
      </div>
      <div class="descript-item-app flex-box" v-if="!showNewUserTips">
        <img class="continer-img" src="//img.233.com/union/union_pc/union-temphone/downapp-bg.png" />
        <div class="desc-image flex-box-center">
          <img :src="imgUrl" alt="" />
        </div>
        <div>
          <div class="f-16 fw-600">扫码下载APP</div>
          <div class="f-14 mt-10">海量题库免费做</div>
          <div class="f-14 mt-10">刷题更便捷</div>
        </div>
      </div>
    </template>
    <div v-else class="descript-item c-p flex-box" @click="handlerOpenLink(item)" v-for="(item, index) in descList.slice(0, 3)" :key="index">
      <div class="desc-image">
        <img :src="item.img()" alt="" />
      </div>
      <div>
        <div class="f-22">{{ item.title }}</div>
        <div class="f-16 color-666 mt-10">{{ item.subtitle }}</div>
      </div>
    </div>
    <!-- </template> -->
    <!-- <template v-else>
      <div class="descript-item c-p flex-box" @click="handlerOpenLink(item)" v-for="(item, index) in descList" :key="index">
        <div class="desc-image">
          <img :src="item.img()" alt="" />
        </div>
        <div>
          <div class="f-22">{{ item.title }}</div>
          <div class="f-16 color-666 mt-10">{{ item.subtitle }}</div>
        </div>
      </div>
    </template> -->
  </div>
</template>

<script>
export default {
  components: {},
  name: "Descript",
  data() {
    return {
      descList: [
        // {
        //   img: () => this.$getImg("description1.png"),
        //   title: "专业师资",
        //   subtitle: "透彻解析重难点",
        //   href: "/search/teacher/list",
        // },
        {
          img: () => this.$getImg("description2.png"),
          title: "考试日历",
          subtitle: "合理定制考试计划",
          href: "/search/calendar",
        },
        {
          img: () => this.$getImg("description3.png"),
          title: "免费题库",
          subtitle: "在线试题等你挑战",
          href: "/center/study/tiku",
        },
      ],
    };
  },
  computed: {
    showNewUserTips() {
      return this.permissionYhq.UActivityFlag === 1 && !this.loginStatus.status;
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    appQrcodeObj() {
      return this.$store.state.common.appQrcodeObj;
    },
    permissionYhq() {
      // return {
      //   UActivityFlag: 1,
      // };
      return this.$store.state.common.permissionYhq;
    },
    imgUrl() {
      return process.env.NODE_ENV === "development" ? "http://gj.rruxe.cn/search/app/markappqrcode" : "/search/app/markappqrcode";
    },
  },
  mounted() {
    this.GetUnionPermissionCourse();
  },
  methods: {
    async GetUnionPermissionCourse() {
      // 是否对学员开放优惠券
      if (!this.loginStatus.status) {
        this.$store.dispatch("common/GetUnionPermissionYhq");
      }
    },
    handlerOpenLink(item) {
      window.open(window.location.origin + item.href);
    },
    openLink(type) {
      switch (type) {
        case 1:
          window.open(location.origin + "/search/cuxiao");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Descript {
  margin: 0 auto;
  @include pageSize;
  // width: 1440px;
  margin-top: 24px;
  padding-left: 304px;
  .new-register-item {
    width: 525px;
    margin-right: 24px;
    flex-shrink: 0;
    background: url(//img.233.com/union/union_pc/union-temphone/yhq-bg.png) no-repeat top center;
    background-size: cover;
    height: 126px;
    &:hover {
      box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
    }
  }
  &.new-register {
    .descript-item {
      width: 350px;
      &:last-of-type {
        display: none;
      }
      flex-shrink: 0;
      padding-left: 14px;
      @media screen and (min-width: 1680px) {
        &:last-of-type {
          display: flex;
        }
        padding-left: 14px;
        display: flex;
        width: 282px;
      }
    }
  }
  .descript-item-app {
    @media screen and (min-width: 1680px) {
      width: 362px;
      padding-left: 34px;
    }
    overflow: hidden;
    padding-left: 14px;
    width: 282px;
    height: 126px;
    background: #fff;
    position: relative;
    .continer-img {
      position: absolute;
      height: 125px;
      left: 14px;
      top: 0;
    }
    .desc-image {
      width: 104px;
      height: 104px;
      border: 1px solid #ffdddb;
      margin-right: 10px;
      img {
        width: 102px;
        height: 102px;
      }
    }
  }
  .descript-item {
    padding-left: 14px;
    background: url(//img.233.com/union/union_pc/union-temphone/blue/descrition1_bg.png) no-repeat top right;
    background-size: cover;
    width: 282px;
    background-color: #fff;
    @media screen and (min-width: 1680px) {
      width: 362px;
      padding-left: 34px;
    }
    height: 126px;
    .desc-image {
      height: 100%;
      img {
        vertical-align: top;
        width: 104px;
        height: 118px;
      }
      margin-right: 20px;
    }
    &:not(:last-of-type) {
      margin-right: 25px;
    }
    &:hover {
      box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
    }
  }
}
</style>
