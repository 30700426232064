<template>
  <div class="introduce">
    <Title title="配套服务"></Title>
    <div class="inter-mak flex">
      <div class="inter-item" :style="item.style" v-for="(item, index) in interList" :key="index">
        <div class="mask flex-box-center" :class="'mask' + index">
          <img class="img" :src="item.img()" alt="" />
          <div class="tit">{{ item.label }}</div>
          <div class="line"></div>
          <div class="sub-title">
            <p v-for="(tit, index) in item.subtitle" :key="index">{{ tit }}</p>
          </div>
          <template v-if="appQrcodeObj.HaveApp">
            <img class="appqrcode" :src="imgUrl" alt="" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";

export default {
  components: {
    Title,
  },
  name: "introduce",
  data() {
    return {
      interList: [
        {
          style: {
            backgroundImage: `url(${this.$getImg("intro/bg1.png", "all")})`,
          },
          img: () => this.$getImg("intro/img1.png", "all"),
          label: "题库在线答疑",
          subtitle: ["8小时内试题答疑，", "学练结合高效提分"],
        },
        {
          style: {
            backgroundImage: `url(${this.$getImg("intro/bg2.png", "all")})`,
          },
          img: () => this.$getImg("intro/img2.png", "all"),
          label: "老师课程答疑",
          subtitle: ["4-24小时内老师答疑，", "真正学透考点不留疑问"],
        },
        {
          style: {
            backgroundImage: `url(${this.$getImg("intro/bg3.png", "all")})`,
          },
          img: () => this.$getImg("intro/img3.png", "all"),
          label: "AI智能题库",
          subtitle: ["大数据分析考点分值，", "专业、智能、高效"],
        },
        {
          style: {
            backgroundImage: `url(${this.$getImg("intro/bg4.png", "all")})`,
          },
          img: () => this.$getImg("intro/img4.png", "all"),
          label: "优质教学服务",
          subtitle: ["陪伴式学习体验，", "坚持对考试效果负责"],
        },
        {
          style: {
            backgroundImage: `url(${this.$getImg("intro/bg5.png", "all")})`,
          },
          img: () => this.$getImg("intro/img5.png", "all"),
          label: "移动学习",
          subtitle: ["支持手机和平板", "随时随地畅享移动学习乐趣"],
        },
      ],
    };
  },
  computed: {
    imgUrl() {
      return process.env.NODE_ENV === "development" ? "http://union.233.com/search/app/markappqrcode" : "/search/app/markappqrcode";
    },
    appQrcodeObj() {
      return this.$store.state.common.appQrcodeObj;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.introduce {
  @include pageSize;
  margin: 0 auto;
  margin-top: 40px;
}
.inter-mak {
  margin-top: 29px;
  .inter-item {
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    width: 230px;
    height: 283px;
    @media screen and (min-width: 1680px) {
      width: 278px;
      height: 341px;
    }
    position: relative;
    &:not(:last-of-type) {
      margin-right: 12px;
    }
    .tit {
      margin-top: 16px;
      font-size: 23px;
    }
    .line {
      margin-top: 20px;
      width: 36px;
      height: 4px;
      @include background_color_jb(270deg, "color2_1", "color2");
    }
    .sub-title {
      display: none;
      font-size: 15px;
      color: #fff;
      text-align: left;
      p {
        line-height: 22px;
      }
      margin-top: 20px;
    }
    .appqrcode {
      margin-top: 10px;
      max-width: 110px;
      max-height: 110px;
      display: none;
    }
    .mask {
      transition: 0.4s;
      height: 100%;
      flex-direction: column;
      &:hover {
        padding-bottom: 25px;
        @media screen and (min-width: 1680px) {
          padding-bottom: 65px;
        }
        &.mask0 {
          background: rgba($color: #568ad5, $alpha: 0.8);
        }
        &.mask1 {
          background: rgba($color: #4ca8b7, $alpha: 0.8);
        }
        &.mask2 {
          background: rgba($color: #d39e5c, $alpha: 0.8);
        }
        &.mask3 {
          background: rgba($color: #7dbe5a, $alpha: 0.8);
          .sub-title {
            text-align: center;
          }
        }
        &.mask4 {
          background: rgba($color: #ee8031, $alpha: 0.8);
          .sub-title {
            text-align: center;
          }
          .appqrcode {
            display: block;
          }
        }
        .img {
          display: none;
        }
        .sub-title {
          display: block;
        }
      }
    }
  }
}
</style>
