<template>
  <div class="course-info">
    <Title>
      <div class="flex" slot="left">
        <div class="f-28 fw-600 shrink0" style="margin-top: -5px">{{ classObj.ClassName }}</div>
        <div class="right-exam flex-box">
          <div class="right-exam-item" @click="active = index" :class="{ active: active === index }" v-for="(item, index) in classObj.childData" :key="index">
            {{ item.ClassName }}
          </div>
        </div>
      </div>
    </Title>
    <div class="class-box flex-box between">
      <div class="left">
        <div class="class-list flex-box">
          <div class="class-item p-r" v-for="(item, index) in courseList" :key="index">
            <div class="class-item-continer">
              <div class="content p-r">
                <div class="f-20 ellipsis--two-line class-title">{{ item.MyclassName }}</div>
                <div class="f-14 color-999 mt-5">有效期：{{ item.Yxq }}</div>
                <div class="tags flex-box">
                  <div class="tag-item" v-for="(tag, index) in item.tagList" :key="index">{{ tag }}</div>
                </div>
                <div class="teacher-box flex-box">
                  <div class="teacher-item" v-for="(teacher, index) in item.Teacherlist" :key="index">
                    <div class="teacher-img">
                      <img :src="teacher.PicPath || ('//img.233.com/union/union_pc/union-temphone/teacher.jpg')" alt="" />
                    </div>
                    <div class="teacher-name">{{ teacher.Name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom flex-box-center">
              <div class="button-default try-button c-p" @click="openLink(8, item)">免费试听</div>
            </div>
          </div>
          <div class="empty" v-if="isLoading && !courseList.length"></div>
          <!-- <div class="class-item"></div>
          <div class="class-item"></div> -->
        </div>
        <div class="couser-nav">
          <div class="couser-nav-content flex between">
            <div class="couser-nav-left">
              <template v-if="hasBkzn">
                <Title>
                  <div slot="left">
                    <span class="f-24 lh-24">新手指南</span>
                    <span class="f-14 color-666 lh-24 ml-10">助您快速了解考试</span>
                  </div>
                </Title>
                <div class="nav-box flex-box">
                  <div class="nav-item flex-box" :style="{ width: navList.length >= 6 ? 550 / 6 + 'px' : '' }" @click="openLink(9, item)" v-for="(item, index) in navList" :key="index">
                    <img :src="item.img | imgFilter" alt="" />
                    <div class="nav-title f-15 mt-15">{{ item.title }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <Title>
                  <div slot="left">
                    <span class="f-24 lh-24">课程试听</span>
                    <span class="f-14 color-666 lh-24 ml-10">精选好课</span>
                  </div>
                </Title>
                <div class="nav-st-box flex-box">
                  <div class="nav-st-item flex c-p" @click="openLink(8)" v-for="(item, index) in tryList" :key="index">
                    <div class="nav-st-item-left mr-20">
                      <div class="teacher-img">
                        <img :src="item.PicPath || ('//img.233.com/union/union_pc/union-temphone/teacher.jpg')" onerror="this.src='//img.233.com/union/union_pc/union-temphone/teacher.jpg'" alt="" />
                      </div>
                    </div>
                    <div class="nav-st-item-right w100">
                      <div>
                        <span class="f-16 teacher-name">{{ item.Name && item.Name.replace('233网校', '网校') }}</span>
                        <span style="float: right">
                          <img :src="'trylearn.png' | imgFilter('all')" alt="" />
                        </span>
                      </div>
                      <div class="color-999 mt-15 lh-16 ellipsis--two-line">{{ item.ClassCname }}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="couser-nav-right">
              <Title>
                <div slot="left" class="pl-5">
                  <span class="f-24 lh-24">在线题库</span>
                  <span class="f-14 color-666 lh-24 ml-10">在线题库等你来挑战</span>
                </div>
              </Title>
              <div class="flex-box couser-nav-right-content">
                <div class="nav-item flex-box-center" :style="item.style" @click="openLink(item.type, item)" v-for="(item, index) in tikuNavList" :key="index">
                  <img :src="item.img()" alt="" />
                  <div class="nav-title f-15 mt-15">{{ item.label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <swiper :options="swiperOptions" v-if="teacherList.length">
          <swiper-slide v-for="(item, index) in teacherList" :key="index">
            <div class="teacher-content">
              <div class="top-content">
                <div class="teacher-icon">
                  <img :src="item.AppPicPath" alt="" />
                </div>
              </div>
              <div class="bootom-content">
                <div class="head-content">
                  <div class="font-bold teacher-name flex-box between">
                    <div>{{ item.Name }}</div>
                    <div class="right-icon f-14 c-p" @click="openLink(8)">
                      听TA讲课
                      <img :src="'trylearn.png' | imgFilter('all')" alt="" />
                    </div>
                  </div>
                  <div class="mt-10 f-16 ellipsis--two-line">{{ item.ShortCredentials }}</div>
                </div>
                <div class="color-999 f-14 teacher-description">{{ item.TeachCharacter }}</div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" v-show="teacherList.length > 1"></div>
        </swiper>
        <div class="empty" v-if="isLoading && !teacherList.length"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";

export default {
  components: {
    Title,
  },
  props: {
    classObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "course-info",
  data() {
    return {
      isLoading: false,
      active: 0,
      hasBkzn: false, // 報考指南
      swiperOptions: {
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
      },
      teacherList: [],
      courseList: [],
      navList: [],
      tikuNavList: [
        {
          label: "章节练习",
          img: () => this.$getImg("zjlx.png", "all"),
          style: {
            backgroundImage: `url(${this.$getImg("zjlx-bg.png", "all")})`,
          },
          type: 5,
        },
        {
          label: "历年真题",
          img: () => this.$getImg("lnzt.png", "all"),
          style: {
            backgroundImage: `url(${this.$getImg("lnzt-bg.png", "all")})`,
          },
          type: 6,
        },
        {
          label: "模拟考试",
          img: () => this.$getImg("mnks.png", "all"),
          style: {
            backgroundImage: `url(${this.$getImg("mnks-bg.png", "all")})`,
          },
          type: 7,
        },
        {
          label: "每日一练",
          img: () => this.$getImg("mryl.png", "all"),
          style: {
            backgroundImage: `url(${this.$getImg("mryl-bg.png", "all")})`,
          },
          type: 10,
        },
      ],
      tryList: [],
    };
  },
  watch: {
    active: {
      handler(v) {
        console.log(v);
        this.GetHomePageCourseByClassID();
        this.GetTeacherListByClassId();
        this.GetUnionBkzn();
      },
      immediate: true,
    },
  },
  computed: {
    curClass() {
      return this.classObj.childData.find((item, index) => index === this.active);
    },
    seniorFlog() {
      return this.$store.state.common.seniorFlog;
    },
  },
  mounted() {},
  methods: {
    openLink(type, item) {
      switch (type) {
        case 1:
          window.open(window.location.origin + "/header/coursetaste/coursetaste.html");
          break;
        case 2:
          window.open(window.location.origin + "/uc/register");
          break;
        case 3:
          window.open(this.advStatus.href);
          break;
        case 4:
          window.open(window.location.origin + item.Href);
          break;
        case 5: // 章节练习
          window.open(window.location.origin + `/center/study/tiku/chapter?domain=${this.curClass.ClassEName}`);
          break;
        case 6: // 历年真题
          window.open(window.location.origin + `/center/study/tiku/paper-past?domain=${this.curClass.ClassEName}`);
          break;
        case 7: // 模拟考试
          window.open(window.location.origin + `/center/study/tiku/paper-mock?domain=${this.curClass.ClassEName}`);
          break;
        case 8:
          window.location.href = window.location.origin + (this.seniorFlog ? "/fine" : "/norm") + "/category/currency/#/?class=" + this.curClass.ClassEName;
          break;
        case 9: // 新手指南
          window.open(window.location.origin + `/bkzn/pc/#/?id=${item.id}`);
          break;
        case 10: // 每日一练
          window.open(window.location.origin + `/center/study/tiku/daily?domain=${this.curClass.ClassEName}`);
          break;
        default:
          break;
      }
    },
    async GetHomePageCourseByClassID() {
      const { Result = [] } = await this.$api.GetHomePageTop3CourseByClassID({
        classid: this.curClass.ExamdaClassId,
      });
      this.isLoading = true;
      this.courseList = Result.slice(0, 3).map((item) => {
        let tagList = [];
        item.HaveRelearn && tagList.push("重学服务");
        item.HaveJc && tagList.push("送教材");
        item.HaveJf && tagList.push("送教辅");
        item.HaveTiKu && tagList.push("送题库");
        return {
          tagList,
          ...item,
        };
      });
      console.log(Result);
    },
    async GetTeacherListByClassId() {
      const { Result } = await this.$api.GetTeacherListByClassId({
        classid: this.curClass.ExamdaClassId,
      });
      this.teacherList = Result;
    },
    async GetUnionBkzn() {
      const { Result } = await this.$api.GetUnionBkzn({
        domain: this.curClass.ClassEName,
        type: 0,
      });
      this.hasBkzn = Result.open === 1;
      if (this.hasBkzn) {
        let imgArr = ["ljaq.png", "bmtj.png", "cfsj.png", "zslq.png", "kssj.png"];
        this.navList = (Result?.data || []).map((item, index) => {
          return {
            img: imgArr[index % imgArr.length],
            ...item,
          };
        });
      } else {
        // 查询试听课程
        const { Result } = await this.$api.GetBaseCourseByClassId({
          classid: this.curClass.ClassId,
        });
        this.tryList = Result.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-info {
  @include pageSize;
  margin: 0 auto;
  margin-top: 80px;
  .right-exam {
    flex-flow: row wrap;
    .right-exam-item {
      margin-left: 10px;
      margin-bottom: 10px;
      min-width: 100px;
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #e9e9e9;
      border-radius: 15px;
      color: #555555;
      font-size: 15px;
      cursor: pointer;
      &.active,
      &:hover {
        color: #fff;
        @include background_color("color1");
      }
    }
  }
  .class-box {
    height: 532px;
    margin-top: 20px;
    .left {
      width: 889px;
      @media screen and (min-width: 1680px) {
        width: 1074px;
      }
      height: 100%;
      .class-list {
        height: 327px;

        .class-item {
          &:hover {
            box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
            .class-title {
              @include font_color("color1");
            }
          }
          background: #fff;
          height: 100%;
          width: 280px;
          @media screen and (min-width: 1680px) {
            width: 342px;
          }
          &:not(:last-of-type) {
            margin-right: 24px;
          }
          .class-item-continer {
            .content {
              height: 247px;
              padding: 15px 15px 0 15px;
              .class-title {
                line-height: 24px;
                max-height: 48px;
                height: 48px;
              }
              .tags {
                margin-top: 10px;
                flex-flow: row wrap;
                .tag-item {
                  text-align: center;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  min-width: 70px;
                  padding: 0 10px;
                  height: 24px;
                  line-height: 24px;
                  font-size: 14px;
                  border-radius: 2px;
                  @include font_color("color2");
                  @include background_color("color2_1");
                }
              }
              .teacher-box {
                position: absolute;
                bottom: 0px;
                left: 20px;
                right: 0;
                .teacher-item {
                  margin-right: 24px;
                  text-align: center;
                  color: #555555;
                  .teacher-img {
                    margin-bottom: 5px;
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    position: relative;
                    border-radius: 50%;
                    background: #e9e9e9;
                    img {
                      max-width: 100%;
                      height: auto;
                    }
                  }
                  .teacher-name {
                    overflow: hidden;
                    width: 60px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
          .bottom {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            .try-button {
              width: 218px;
              @media screen and (min-width: 1680px) {
                width: 262px;
              }
              height: 50px;
              line-height: 50px;
              font-size: 18px;
              transition: 0.2s;
              border-radius: 24px;
              &:hover {
                color: #fff !important;
                @include background_color("color1");
                transform: translateY(-5px);
              }
            }
          }
        }
      }
      .couser-nav {
        margin-top: 24px;
        // box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
        background: #fff;
        height: 181px;
        width: 100%;
        padding: 22px 16px 15px 15px;
        .couser-nav-content {
          .couser-nav-left {
            flex-shrink: 0;
            border-right: 1px solid #eeeeee;
            width: 497px;
            @media screen and (min-width: 1680px) {
              width: 597px;
            }
            .nav-box {
              margin-top: 15px;
              padding-right: 15px;
              .nav-item {
                white-space: nowrap;
                flex-direction: column;
                width: 110px;
                height: 103px;
                background-size: cover;
                cursor: pointer;
                padding: 10px 5px;
                // &:not(:last-of-type) {
                //   margin-right: 13px;
                // }
                img {
                  width: 42px;
                  height: 42px;
                }
                &:hover {
                  box-shadow: 3px 3px 20px 0px rgba(212, 207, 206, 0.4);
                }
              }
            }
            .nav-st-box {
              padding-right: 15px;
              margin-top: 12px;
              .nav-st-item {
                &:first-of-type {
                  margin-right: 20px;
                }
                width: 50%;
                height: 102px;
                padding: 13px 15px;
                border: 1px solid #f4f4f4;
                &:hover {
                  box-shadow: 5px 4px 16px 0px rgba(202, 202, 202, 0.3);
                }
                .teacher-img {
                  width: 68px;
                  height: 100%;
                  overflow: hidden;
                  position: relative;
                  background: #f2f4f3;
                  img {
                    max-height: 74px;
                    width:  auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                  }
                }
                .teacher-name {
                  position: relative;
                  padding-left: 11px;
                  &::before {
                    position: absolute;
                    content: " ";
                    left: 0;
                    top: 4px;
                    @include background_color_jb(0deg, "colorFFF", "color2");
                    border-radius: 2px;
                    height: 16px;
                    width: 4px;
                  }
                }
              }
            }
          }
          .couser-nav-right {
            width: 397px;
            @media screen and (min-width: 1680px) {
              width: 476px;
            }
            padding-left: 27px;
            .couser-nav-right-content {
              margin-top: 15px;
              .nav-item {
                cursor: pointer;
                width: 25%;
                height: 102px;
                background-size: cover;
                background-repeat: no-repeat;
                flex-flow: column;
                // background: red;
                color: #fff;
                &:not(:last-of-type) {
                  margin-right: 18px;
                }
              }
            }
          }
        }
      }
    }
    .right {
      height: 100%;
      // box-shadow: 0px 5px 29px 3px rgba(123, 144, 161, 0.08);
      width: 285px;
      @media screen and (min-width: 1680px) {
        width: 342px;
      }
      background: #fff;
      .top-content {
        height: 314px;
        position: relative;
        background: url(//img.233.com/union/union_pc/union-temphone/teacher-bg1.png) no-repeat top center;
        background-size: cover;
        overflow: hidden;
        .teacher-icon {
          position: absolute;
          left: 0;
          right: 0;
          top: 10px;
          text-align: center;
          img {
            width: 227px;
            @media screen and (min-width: 1680px) {
              width: 272px;
            }
            vertical-align: bottom;
          }
        }
      }
      .bootom-content {
        height: 218px;
        padding: 24px 0 0 15px;
        position: relative;
        .head-content {
          // margin-top: 18px;
          .teacher-name {
            font-size: 24px;
            position: relative;
            line-height: 26px;
            // &::before {
            //   position: absolute;
            //   content: " ";
            //   left: -17px;
            //   top: 0;
            //   @include background_color_jb(0deg, "colorFFF", "color1");
            //   border-radius: 2px;
            //   height: 22px;
            //   width: 4px;
            // }
            .right-icon {
              width: 111px;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border-radius: 15px 0 0 15px;
              border: 1px solid #fff;
              border-right: none;
              @include font_color("color2");
              @include border_color("color2");
              img {
                width: 21px;
                height: 16px;
              }
            }
          }
        }
        .teacher-description {
          position: absolute;
          bottom: 10px;
          overflow: hidden;
          line-height: 26px;
          height: 104px;
          padding-right: 15px;
          display: -webkit-box;
          max-height: 104px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
      .swiper-pagination-custom,
      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 225px;
      }
      /deep/.swiper-pagination {
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          background: rgba(#f1eeee, 0.7);
          opacity: 0.7;
          border-radius: 50%;
        }
        .swiper-pagination-bullet-active {
          width: 30px;
          height: 10px;
          border-radius: 5px;
          @include background_color("color1");
        }
      }
    }
  }
  .empty {
    height: 100%;
    width: 100%;
    background: #fff;
    background-image: url("https://img2.233.com/wx/union/pc/home/default_no_course.png");
    background-size: 200px 200px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
