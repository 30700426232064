<template>
  <div class="teacher">
    <div class="teacher-continer-info">
      <Title>
        <div class="flex" slot="left">
          <div class="f-28 fw-600 shrink0" style="margin-top: -5px">师资团队</div>
          <div class="right-exam flex-box">
            <div class="right-exam-item" @click="active = index" :class="{ active: active === index }" v-for="(item, index) in examList" :key="index">
              {{ item.ClassName }}
            </div>
          </div>
        </div>
      </Title>
    </div>
    <div class="contents">
      <div class="info flex">
        <div class="left">
          <div class="title">云集优秀师资 保障课程品质</div>
          <div class="sub-title f-18 color-fff">{{ teacherNames }}等领衔主讲</div>
          <div class="teacher-desc flex">
            <div class="teacher-desc-item" v-for="item in teacherDescList" :key="item.label">
              <div class="c-title">{{ item.label }}</div>
              <div class="color-fff f-14 c-sub-title">{{ item.subtitle }}</div>
            </div>
          </div>
        </div>
        <div class="right flex-box">
          <div class="teacher-bg p-r" v-for="(item, index) in teachers.slice(0, 3)" :key="index">
            <div class="teacher-img">
              <img :src="item.AppPicPath" alt="" />
            </div>
            <div class="teacher-info p-a">
              <div class="f-26 color-fff">{{ item.TeacherName }}</div>
              <div class="f-13 color-fff z-sub-title">{{ item.Expression }}</div>
              <div class="exam-button">{{ item.ClassCName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
export default {
  components: {
    Title,
  },
  name: "teacher",
  data() {
    return {
      teacherDescList: [
        {
          label: "专业老师",
          subtitle: "诸多老师毕业于985、211等高校，拥有过硬的学科知识。",
        },
        {
          label: "经验丰富",
          subtitle: "老师平均教龄超过6年，部分高达20年。",
        },
        {
          label: "双师资授课",
          subtitle: "部分考试每科聘请2位老师授课，更好地满足的学习需求",
        },
      ],
      teachers: [],
      active: 0,
      teacherNames: null,
    };
  },
  watch: {
    active: {
      handler(v) {
        this.handlerQueryTeacherList();
      },
      immediate: true,
    },
  },
  computed: {
    examList() {
      return this.$store.state.common.examList;
    },
    curExam() {
      return this.examList[this.active];
    },
  },
  mounted() {},
  methods: {
    async handlerQueryTeacherList() {
      const { Result } = await this.$api.GetTeacherTeamInfo({
        classid: this.curExam.ExamdaClassId,
      });
      this.teacherNames = Result.TeacherNameStr;
      this.teachers = Result.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher {
  .teacher-continer-info {
    @include pageSize;
    margin: 0 auto;
    margin-top: 60px;
    .right-exam {
      flex-flow: row wrap;
      .right-exam-item {
        margin-left: 10px;
        margin-bottom: 10px;
        min-width: 100px;
        padding: 0 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #e9e9e9;
        border-radius: 15px;
        color: #555555;
        font-size: 15px;
        cursor: pointer;
        &.active,
        &:hover {
          color: #fff;
          @include background_color("color1");
        }
      }
    }
  }
  .contents {
    height: 550px;
    width: 100%;
    background: #666;
    background: url("//img.233.com/wx/img/index/2021/cont-bg2.jpg") no-repeat top center;
    background-size: cover;
    margin-top: 18px;
    .info {
      padding: 0;
      @media screen and (min-width: 1680px) {
        padding: 0 45px 0 79px;
      }
      @include pageSize;
      margin: 0 auto;
      height: 100%;
      .left {
        margin-right: 60px;
        height: 100%;
        .title {
          position: relative;
          margin-top: 109px;
          width: 336px;
        
          color: #fcd4a2;
          font-size: 48px;
          letter-spacing: 8px;
          line-height: 54px;
          &::before {
            content: " ";
            position: absolute;
            left: -22px;
            top: 10px;
            width: 2px;
            height: 102px;
            background: #fcd4a2;
          }
        }
        .sub-title {
          margin-top: 30px;
          line-height: 18px;
        }
        .teacher-desc {
          margin-top: 60px;
          margin-left: -42px;
          .teacher-desc-item {
            width: 183px;
      
            padding: 0 20px;
            .c-title {
              font-size: 24px;
              color: #fcd4a2;
            }
            .c-sub-title {
              margin-top: 22px;
              line-height: 21px;
            }
            position: relative;
            &:not(:last-of-type) {
              &::after {
                content: " ";
                position: absolute;
                right: 0;
                top: -20px;
                width: 1px;
                height: 121px;
                background: linear-gradient(0, transparent, rgba(255, 255, 255, 0.1), transparent);
              }
            }
          }
        }
      }
      .right {
        .teacher-bg {
          background: linear-gradient(216deg, #292940 0%, #171827 76%);
          width: 217px;
          @media screen and (min-width: 1680px) {
            width: 260px;
          }
          height: 100%;
          .teacher-img {
            opacity: 0.8;
            width: 100%;
            height: 550px;
            overflow: hidden;
            img {
              padding-top: 68px;
              width: 100%;
            }
          }
          .teacher-info {
            left: 0;
            right: 0;
            padding-left: 30px;
            bottom: 62px;
            .z-sub-title {
              margin-top: 13px;
            }
            .exam-button {
              margin-top: 27px;
              width: 180px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              border: 1px solid #fcd4a2;
              border-radius: 15px;
              cursor: pointer;
              color: #fcd4a2;
              &:hover,
              &.active {
                background: #fcd4a2;
                border: 1px solid #fcd4a2;
                color: #181824;
              }
            }
          }
          &:hover {
            .teacher-img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
