<template>
  <div class="title-box flex-box between">
    <slot name="left">
      <div class="left" :style="titleStyle">
        <div class="fw-600 lh-40">{{ title }}</div>
      </div>
    </slot>
    <div class="right">
      <slot>
        <div v-if="showmore" class="more c-p" @click="$emit('more')"></div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "sdtitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    showmore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    titleStyle() {
      return {
        // backgroundImage: `url(${this.$options.filters.ImgFilter('title-bg.png')})`
      }
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.title-box {
  .left {
    background-size: cover;
    font-size: 28px;
    // padding-left: 18px;
  }
  .right {
    .more {
      background: url(//img.233.com/union/union_pc/union-temphone/more.png) no-repeat top center;
      background-size: cover;
      width: 59px;
      height: 14px;
    }
  }
}
</style>
