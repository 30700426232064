<template>
  <div class="subject">
    <div class="subject-continer flex-box-center">考试分类</div>
    <div class="exam-box">
      <div class="has-more">
        <div class="exam-continer" :class="{ nomore: examList.length < 3 }">
          <div class="exam-item" v-for="(item, index) in examList.slice(0, 3)" :key="index">
            <div class="left flex-box">
              <img :src="domainImg(index)" class="mr-5" alt="" />
              {{ item.ClassName }}
            </div>
            <div class="labels">
              <a v-for="ii in item.childData" :key="ii.ClassId" :href="ii.Href" target="_blank">{{ ii.ClassName }}</a>
            </div>
          </div>
        </div>
        <div v-if="examList.length >= 3" class="bottom-more">
          <p class="color-333 fw-600 f-22">
            查看更多
            <img class="ml-5" src="https://img2.233.com/wx/union/pc/home/right-but.png" />
          </p>
        </div>
      </div>
      <div class="more">
        <div class="box" v-for="item in examList" :key="item.ClassId">
          <span>{{ item.ClassName }}</span>
          <div class="labels">
            <a v-for="ii in item.childData" :key="ii.ClassId" :href="ii.Href" target="_blank">{{ ii.ClassName }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "sdnav",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    domainImg() {
      return function (index) {
        return this.$getImg(`domain${index + 1}.png`);
      };
    },
    examList() {
      return this.$store.state.common.examList;
    },
  },
};
</script>

<style lang="scss" scoped>
.subject {
  background: #fff;
  position: absolute;
  top: 0;
  @media screen and (min-width: 1680px) {
    left: calc(50% - 720px);
  }
  left: calc(50% - 600px);
  z-index: 11;
  color: #fff;
  width: 280px;
  .subject-continer {
    height: 40px;
    font-size: 18px;
    color: #fff;
    @include background_color("color1");
  }
  flex-shrink: 0;
  .exam-box {
    height: 480px;
    overflow: hidden;
    z-index: 333;
    background: #fff;
    padding: 20px 14px 0 14px;
    .exam-continer {
      height: 410px;
      overflow: hidden;
      &.nomore {
        height: 100%;
      }
    }
    .exam-item {
      font-weight: bold;
      color: #333333;
      cursor: pointer;
      .left {
        font-size: 18px;
      }
      .labels {
        padding: 10px 0 15px 0;
        a {
          display: inline-block;
          font-weight: 400;
          cursor: pointer;
          color: #555;
          font-size: 14px;
          margin-right: 10px;
          line-height: 28px;
          text-decoration: none;
          &:hover {
            @include font_color("color1");
          }
        }
      }
    }
    .more {
      position: absolute;
      top: 0;
      left: 280px;
      transition: 0.2s;
      width: 0;
      min-height: 480px;
      max-height: 600px;
      box-sizing: border-box;
      background: rgba($color: #fff, $alpha: 0.97);
      // overflow: hidden;
      overflow-y: auto;
      .box {
        visibility: hidden;
        display: flex;
        line-height: 0;
        padding: 8px 0;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        span {
          font-weight: bold;
          color: #333;
          font-size: 16px;
          display: inline-block;
          width: 92px;
          overflow: hidden;
          line-height: 30px;
        }
        .labels {
          flex: 1;
          a {
            display: inline-block;
            font-weight: 400;
            cursor: pointer;
            color: #555555;
            font-size: 14px;
            padding: 0 13px;
            line-height: 30px;
            text-decoration: none;
            &:hover {
              @include font_color("color1");
            }
          }
        }
      }
    }
    &:hover .more {
      padding: 10px 25px 0 25px;
      width: 694px;
      height: 519px;
      .box {
        visibility: inherit;
        visibility: unset;
      }
    }
    .bottom-more {
      cursor: pointer;
      padding-top: 7px;
      border-top: 1px dashed #e8e8e8;
    }
  }
}
</style>
